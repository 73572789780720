.color_icon {
  & svg {
    color: red;
  }
}

.main_table {
  border-radius: 30px;
  padding: 10px;
  /* background-image: url("../../../public/logo-transparente.png");
  background-size: 18%;
  background-repeat: no-repeat;
  background-position: center;
 */
}
