
 .modules__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 12px;
    margin: 11px 0px 25px 0px;
    background-color: rgb(205 224 244 / 1);
    border-radius: 15px;
   
  }
  .modules__header2{
   flex-direction: column; 
   gap: 10px;
   align-items: flex-start;
  }
  .modules__header-title{
    font-size: 24px;
    font-weight: bolder;
  }

  .page-title {
    font-weight: bold !important;
    font-size: 1.5em !important;
    color:#1565c0;
  }

  .buttonDrawer{
    width: 40px;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0  15px 0 0 ;
  button{
    margin: 0;
  }
  }

  .button-header{
    padding: 10px 20px !important;
    font-weight: bold !important;
    letter-spacing: 0.09px !important;
    border-radius: 10px !important;
    background-color: #2196f3 !important;
  }


  
  