.main-card {
  display: grid;
  gap: 16px;
}
.main-card__row-table__group {
  margin: 0 0 18px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main-card__row-table {
  display: grid;
  grid-template-columns: minmax(auto, 120px) 1fr;
  border-bottom: solid 1px #e9edef;
  &:last-child {
    border: 0;
    grid-column: 1/-1;
  }
  .main-card__row-table-cell {
    padding: 8px;
    text-transform: capitalize;
    .main-card__row-table-title {
      color: #060c23;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
.main-card__row-table-2 {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #e9edef;
  .main-card__row-table-cell {
    padding: 8px;
    text-transform: capitalize;
    .main-card__row-table-title {
      color: #060c23;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
.main-card__row-table-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #e9edef;
  .main-card__row-table-cell {
    padding: 8px;
    text-transform: capitalize;
    .main-card__row-table-title {
      color: #060c23;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
.main-card__row-table-cell-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-card__inner {
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr 360px;
  align-items: flex-start;
}

// .main-card__row {
//   background: white;
//   border-radius: 30px;
//   display: grid;
//   padding: 14px 26px;
//   box-sizing: border-box;
//   gap: 8px;
//   margin-top: 17px;
//   align-items: center;
//   transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
//   opacity: 0.7;
//   &:hover {
//     opacity: 1;
//   }
//   .main-card__header {
//     display: grid;
//     grid-template-columns: 26px 1fr 1fr;
//     gap: 8px;
//     cursor: pointer;

//     .main-card__title {
//       color: $blue-dark-color;
//     }

//     svg {
//       color: $blue-dark-color;
//       font-size: 26px;
//       transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
//     }
//   }
//   .main-card__body {
//     display: none;
//   }
//   &.main-card__row-active {
//     padding: 30px;
//     gap: 22px;
//     & .main-card__header .main-card__arrow {
//       transform: rotate(180deg);
//     }
//     .main-card__body {
//       display: block;
//     }
//   }
// }

.main_card_normal {
  background-color: #fff;
  border-radius: 30px;
  display: grid;
  padding: 30px;
  box-sizing: border-box;
}

.main_card__buttons-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-card__buttons {
  display: flex;
  justify-content: flex-end;
}

// .main-card__button {
//   color: $blue-dark-color;
// }

.main-card__container__button {
  display: flex;
  justify-content: center;
}
.card-date__text {
  display: flex;
  justify-content: center;
}
